import React, {useEffect, useState} from "react";

const AddHomeBanner = () => {

    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (window.navigator.userAgent.indexOf('iPhone') !== -1) {
            if (!window.navigator.standalone) {
                setShowMessage(true)
            }
        }
    }, [])

    return (
        <>
            {showMessage ?
                <div className={"fixed w-[95%] bottom-2 bg-primary text-white text-xs left-2/4 -translate-x-2/4 rounded p-2 border-white border"}>
                    <span>Přidejte si naši aplikaci přímo na obrazovku svého telefonu. Pomocí Sdílet → Přidat na plochu.</span>
                </div>
                :
                null
            }
        </>
    )
}

export default AddHomeBanner;

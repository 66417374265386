import React from "react";
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import Home from "./pages/Home";
import Results from "./pages/Results";

const App = () => {

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home/>}/>
            <Route path="results" element={<Results/>}/>
            <Route path="*" element={
                <div className={"h-screen w-full flex flex-col justify-center items-center"}>
                    <h1 className={"text-2xl mb-3"}>Page Not Found</h1>
                    <Link to={"/"} className={"text-blue-500 underline-offset-2 underline"}>Back to home</Link>
                </div>
            }/>
          </Route>
        </Routes>
      </BrowserRouter>
  )
}

export default App;

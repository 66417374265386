import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import AddHomeBanner from "../component/AddHomeBanner";

const Home = () => {

    const [showBanner, setShowBanner] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setShowBanner(false)
        }, 10000)
    }, [])

    return (
        <div className={"relative min-h-screen w-full container mx-auto bg-cover bg-center bg-primatorky"}>
            <div className={"p-3"}>
                <div>
                    <img src="./image/primatorky_logo-w.png" className={"w-[180px]"} alt="Logo-primatorky-110"/>
                </div>
                <div className={"text-end block"}>
                    <img src="./image/skupina_seven.png" className={"w-[105px] my-3 inline-block"} alt="skupina seven"/>
                </div>
                <div className={"text-end"}>
                    <p className={"font-ngbold text-white text-2xl uppercase"}>110. SEV.EN<br/>Primátorky</p>
                    <p className={"font-ngreg text-white text-2xl uppercase"}>Legendární veslařský<br/>souboj na Vltavě</p>
                    <p className={"font-ngbold text-white text-2xl uppercase"}>2. - 4. června 2023</p>
                    <p className={"font-ngreg text-white text-2xl uppercase"}>Rašínovo nábřeží, Praha</p>
                    <div className={"block w-full my-4"}>
                        <Link
                            to={"results"}
                            className={"bg-white text-primary px-4 py-2 text-xl block text-center w-full hover:bg-primary hover:text-white font-ngbold"}
                        >Výsledky 2023</Link>
                    </div>
                    <div className={"flex justify-end gap-x-3 my-3 self-center"}>
                        <img src="./image/partner/pvs-03.png" className={"max-w-[40px] h-auto w-full"} alt=""/>
                        <img src="./image/partner/cvk-04.png" className={"max-w-[40px] h-auto w-full"} alt=""/>
                        <img src="./image/partner/cvs-02.png" className={"max-w-[90px] h-auto w-full"} alt=""/>
                    </div>
                    <p className={"font-ngbold text-green-500 text-xl uppercase"}>
                        <a href="https://primatorky.cz/" target={"_blank"} rel="noreferrer">www.primatorky.cz</a>
                    </p>
                </div>
            </div>

            <div className={"flex flex-wrap gap-2 justify-around bg-primary p-3"}>
                <div className={"flex self-center max-w-[50px]"}>
                    <img src="./image/partner/metrostav-02.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/cesti_veslari-03.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/dobry_andel-04.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/prague-05.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/ct-06.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/impuls-07.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/i_sport-08.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/fcc-09.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/mercedes_benz-10.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/staropramen-11.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/dap-12.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/povodi_vltavy-13.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/mestska_cast_praha_2.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[40px] md:max-w-[90px]"}>
                    <img src="./image/partner/mestska_cast_praha_4.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[50px] md:max-w-[100px]"}>
                    <img src="./image/partner/mestska_cast_praha_5-16.png" alt=""/>
                </div>
                <div className={"flex self-center max-w-[40px] md:max-w-[90px]"}>
                    <img src="./image/partner/caus.png" alt=""/>
                </div>
            </div>
            {showBanner ? <AddHomeBanner/> : null}
        </div>
    )
}

export default Home;

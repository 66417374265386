import React from "react";

const Results = () => {
    return (
        <div className={"container mx-auto"}>
            <iframe src="https://results.sportis.cz/cs/competitions/2342/" frameborder="0" className={"w-full h-screen"} id={"sportis"} title={"Výsledky Primátorky 2023"}>
            </iframe>
        </div>
    )
}

export default Results;
